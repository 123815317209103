<template>
  <div class="releaseFormDialog">
    <el-dialog
      :title="dialogData.title"
      :visible.sync="dialogData.dialogVisible"
    >
      <component
        :is="dialogData.type"
        :edit-id="dialogData.id"
        @editSuccess="editSuccess"
        @editCancel="editCancel"
      />
    </el-dialog>
  </div>
</template>

<script>
import ReleaseDemand from '@/components/ReleaseDemand'
import ReleaseDesk from '@/components/ReleaseDesk'
export default {
  name: 'ReleaseFormDialog',
  components: {
    ReleaseDemand,
    ReleaseDesk,
  },
  props: {
    dialogData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  methods: {
    editSuccess() {
      this.dialogData.dialogVisible = false
      this.$emit('editSuccess')
    },
    editCancel() {
      this.dialogData.dialogVisible = false
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.releaseFormDialog {
  width: 100%;
  height: 100%;
}
</style>
